





















































































































































































































































































import EventItem from "@/components/plannerComponent/components/eventItem/EventItem";
export default EventItem;
